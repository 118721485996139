import {Flag, Tooltip} from 'platform/components';
import {Box, Center, Hide, HStack, Show, Text, VStack} from 'platform/foundation';
import {useFormatCurrency, useFormatPercentage} from 'platform/locale';
import styled from 'styled-components';
import {match, Pattern} from 'ts-pattern';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  AvailableCar,
  i18n,
  mileageFormatter,
  powerFormatter,
  PriceReportType,
} from '@price-report/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {CompareTrend} from '../../../components/CompareTrend/CompareTrend';
import {CatalogueType} from '../../../hooks/useCatalogue';
import {useVehicleTranslatedInfo} from '../../../hooks/useVehicleTranslatedInfo';
import {getDifference} from '../../../utils/getDifference';
import {getPrice} from '../../../utils/getPrice';
import {mapMetadaCurrency} from '../../../utils/mapMetadaCurrency';
import {translateSellerType} from '../../../utils/translateSellerType';
import {FeatureList} from './FeatureList';
import {VerticalValue} from './VerticalValue';

const DEFAULT_VALUE = 'N/A';

interface ColumnHeaderProps extends TestIdProps {
  priceReport: PriceReportType;
  catalogue: CatalogueType;
  vehicle: AvailableCar;
  vehiclePosition: number | Nullish;
  isMyVehicle: boolean;
}

export function ColumnHeader(props: ColumnHeaderProps) {
  const formatPercentage = useFormatPercentage();
  const formatCurrency = useFormatCurrency();

  const {getTransmission, getFuelType, getDrive, getCarStyle} = props.catalogue;

  const vehicleSellerValues = [
    String(props.vehicle.days_on_stock ?? DEFAULT_VALUE),
    !props.isMyVehicle
      ? (translateSellerType(props.vehicle.seller?.type ?? 'SELLERTYPE_PRIVATE_SELLER') ??
        DEFAULT_VALUE)
      : '',
  ];

  const vehicleDetailValues = [
    getTransmission(props.vehicle.transmission),
    getFuelType(props.vehicle.fuel_type),
    powerFormatter(props.vehicle.power),
    getDrive(props.vehicle.drive_type),
    getCarStyle(props.vehicle.car_style),
  ];

  const {data: vehicleTranslatedInfo} = useVehicleTranslatedInfo(
    isNotNil(props.vehicle.model)
      ? {...props.priceReport.vehicle, modelFamily: props.vehicle.model}
      : props.priceReport.vehicle
  );
  const vehicleName = match([
    props.vehicle.ad_title,
    props.isMyVehicle,
    props.priceReport.vehicle.vehicleTitle,
  ])
    .with([Pattern.nonNullable, false, Pattern.any], () => props.vehicle.ad_title)
    .with([Pattern.any, true, Pattern.nonNullable], () => props.priceReport.vehicle.vehicleTitle)
    .otherwise(() => `${vehicleTranslatedInfo.make} ${vehicleTranslatedInfo.model}`);
  const vehiclePrice = getPrice(props.vehicle, props.priceReport);
  const priceDifference = getDifference(
    vehiclePrice,
    props.priceReport.sellingPrice.recommended.value
  );
  const currency = props.priceReport.sellingPrice.recommended.currency;
  const yearDifference = getDifference(
    props.vehicle.year,
    props.priceReport.vehicle.firstRegistrationOnYear
  );
  const mileageDifference = getDifference(props.vehicle.mileage, props.priceReport.vehicle.mileage);
  const featuresDifference = getDifference(
    props.vehicle.feature_score,
    props.priceReport.featuresLevel,
    100
  );

  return (
    <VStack>
      <Box padding={2} borderBottom="1px solid" borderColor="general.separator" height={16}>
        <ThreeLinesTruncate>
          <Text title={vehicleName ?? undefined} size="xSmall" overflowWrap="anywhere">
            {vehicleName}
          </Text>
        </ThreeLinesTruncate>
      </Box>
      <Box padding={2} borderBottom="1px solid" borderColor="general.separator" height={18}>
        <VStack spacing={1}>
          <HStack spacing={1} align="center">
            <Text size="xSmall" alternative>
              {formatCurrency(vehiclePrice, currency)}
            </Text>
            <Show when={props.vehicle.vat_reclaimable}>
              <Tooltip label={i18n.t('general.labels.vatDeductible')}>
                <Flag
                  size="small"
                  colorScheme="black"
                  label={i18n.t('general.labels.vat')}
                  data-testid={suffixTestId('vatDeductible', props)}
                />
              </Tooltip>
            </Show>
          </HStack>
          <Hide when={props.isMyVehicle}>
            <CompareTrend
              value={priceDifference}
              formatter={(value) => formatCurrency(value, currency) ?? ''}
              reverseColor
            />
          </Hide>
          <Hide when={isNil(props.vehicle.first_scraped_price_with_vat)}>
            <Text size="xxSmall" color="secondary" strikethrough>
              {formatCurrency(
                props.vehicle.first_scraped_price_with_vat!,
                mapMetadaCurrency(props.vehicle.first_scraped_price_currency) ?? currency
              )}
            </Text>
          </Hide>
        </VStack>
      </Box>
      <Box padding={2} borderBottom="1px solid" borderColor="general.separator" height={7}>
        <Center height="100%" justify="flex-start">
          <Text size="xSmall">{`${props.vehiclePosition}.`}</Text>
        </Center>
      </Box>
      <Box padding={2} borderBottom="1px solid" borderColor="general.separator" height={14}>
        <VerticalValue
          value={props.vehicle.feature_score}
          difference={props.isMyVehicle ? undefined : featuresDifference}
          formatter={(value) => (isNotNil(value) ? formatPercentage(value, 'down') : DEFAULT_VALUE)}
          differenceFormatter={(value) =>
            // Feature difference is already rounded – formatting has to be the nearest due to rounding problems (0.29 * 100 = 28.999999999999996)
            isNotNil(value) ? formatPercentage(value, 'nearest') : DEFAULT_VALUE
          }
          withRank
          isMyVehicle={props.isMyVehicle}
          data-testid={suffixTestId('featureScore', props)}
        />
      </Box>
      <Box padding={2} borderBottom="1px solid" borderColor="general.separator" height={14}>
        <VerticalValue
          value={props.vehicle.mileage}
          difference={props.isMyVehicle ? undefined : mileageDifference}
          formatter={mileageFormatter}
          differenceReverseColor
          isMyVehicle={props.isMyVehicle}
          data-testid={suffixTestId('mileage', props)}
        />
      </Box>
      <Box padding={2} borderBottom="1px solid" borderColor="general.separator" height={14}>
        <VerticalValue
          value={props.vehicle.year}
          difference={props.isMyVehicle ? undefined : yearDifference}
          isMyVehicle={props.isMyVehicle}
          data-testid={suffixTestId('year', props)}
        />
      </Box>
      <Box borderBottom="1px solid" borderColor="general.separator">
        <FeatureList
          spacing={1}
          textColor="primary"
          values={vehicleSellerValues}
          isMyVehicle={props.isMyVehicle}
        />
      </Box>
      <Box borderBottom="1px solid" borderColor="general.separator">
        <FeatureList
          spacing={1}
          textColor="primary"
          values={vehicleDetailValues}
          isMyVehicle={props.isMyVehicle}
          data-testid={suffixTestId('details', props)}
        />
      </Box>
    </VStack>
  );
}

const ThreeLinesTruncate = styled.div`
  /* This property doesn't exists without -webkit prefix, but it is compatible even on browsers not based on webkit */
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
