import {TestIdProps} from 'shared';

import {FormControlProps} from '../../types/FormControlProps';

export interface HiddenInputProps extends FormControlProps<string | number | null>, TestIdProps {}

export function HiddenInput(props: HiddenInputProps) {
  return (
    <input
      type="hidden"
      name={props.name}
      value={props.value ?? ''}
      data-name={props.name}
      data-testid={props['data-testid']}
    />
  );
}
