import {Button, FormControl} from 'platform/components';
import {Heading, Show, Text, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {i18n} from '@price-report/shared';

import {suffixTestId, TestIdProps, useRequiredParams, useToggle} from 'shared';

import {useCatalogue} from '../../../hooks/useCatalogue';
import {useGetCarFeaturesListQuery} from '../../../store/priceReportApi';
import {VehicleFormType} from '../types/VehicleFormType';
import {AllFeaturesFiled} from './AllFeaturesFiled';
import {FeaturesField} from './FeaturesField';

interface VehicleFeaturesProps extends TestIdProps {
  control: FormControl<VehicleFormType>;
  formApi: UseFormReturn<VehicleFormType>;
}

export function VehicleFeatures(props: VehicleFeaturesProps) {
  const {requestId} = useRequiredParams();
  const {hasFeatureTranslation} = useCatalogue();
  const [isOpen, toggleOpen] = useToggle();

  const {data} = useGetCarFeaturesListQuery({id: requestId});
  const translatableFeatures = data?.features?.filter(hasFeatureTranslation) ?? [];

  return (
    <VStack spacing={6} align="flex-start">
      <Heading size={2}>{i18n.t('page.create.labels.vehicleFeatures')}</Heading>
      <Text color="secondary">{i18n.t('page.create.labels.vehicleFeaturesDescription')}</Text>
      <Heading size={4}>{i18n.t('page.create.labels.selectFeatures')}</Heading>
      <FeaturesField
        control={props.control}
        features={translatableFeatures}
        data-testid={suffixTestId('featuresField', props)}
      />
      <Button
        title={i18n.t('page.create.labels.showAllFeatures')}
        variant="ghostLink"
        rightIcon={isOpen ? 'navigation/expand_less' : 'navigation/expand_more'}
        onClick={toggleOpen}
        data-testid={suffixTestId('showAllFeatures', props)}
      />
      <Show when={isOpen}>
        <AllFeaturesFiled
          control={props.control}
          data-testid={suffixTestId('allFeaturesFiled', props)}
        />
      </Show>
    </VStack>
  );
}
