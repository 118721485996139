import {DataStatus, Parameters} from 'platform/components';
import {Box, Grid, HStack, Space, Text, VStack} from 'platform/foundation';
import {useFormatCurrency, useFormatNumber, useFormatPercentage} from 'platform/locale';
import {match, Pattern} from 'ts-pattern';

import {isEmpty, isNil, reject} from 'ramda';
import {isNotNil, isTrue} from 'ramda-adjunct';

import {i18n, powerFormatter, PriceReportType} from '@price-report/shared';

import {parseDate, RequiredTestIdProps, suffixTestId} from 'shared';

import {VehicleImage} from '../../../../../components/VehicleImage/VehicleImage';
import {useCatalogue} from '../../../../../hooks/useCatalogue';
import {useVehicleTranslatedInfo} from '../../../../../hooks/useVehicleTranslatedInfo';
import {useGetPriceReportCarDetailQuery} from '../../../../../store/priceReportApi';
import {getDifference} from '../../../../../utils/getDifference';
import {getPrice} from '../../../../../utils/getPrice';
import {mapMetadaCurrency} from '../../../../../utils/mapMetadaCurrency';
import {translateSellerType} from '../../../../../utils/translateSellerType';
import {ComparedValue} from './ComparedValue';

interface CarTooltipProps extends RequiredTestIdProps {
  priceReport: PriceReportType;
  adId?: string;
  isMyVehicle?: boolean;
  showShadow?: boolean;
}

export function CarTooltip(props: CarTooltipProps) {
  const formatPercentage = useFormatPercentage();
  const formatNumber = useFormatNumber();
  const formatCurrency = useFormatCurrency();

  const {data, isLoading, isFetching, isError} = useGetPriceReportCarDetailQuery(
    {reportId: props.priceReport.id, vehicleId: props.adId!},
    {skip: isNil(props.adId) || props.isMyVehicle}
  );

  const {data: translatedVehicleInfo} = useVehicleTranslatedInfo(
    isNotNil(data?.model)
      ? {...props.priceReport?.vehicle, modelFamily: data.model}
      : props.priceReport?.vehicle
  );

  const {getTransmission, getFuelType, getDrive, getCarStyle} = useCatalogue();

  if (props.isMyVehicle) {
    return null;
  }

  const vehicleDotListItems: string[] = reject(isEmpty, [
    getTransmission(data?.transmission),
    getFuelType(data?.fuel_type),
    powerFormatter(data?.power),
    getDrive(data?.drive_type),
    getCarStyle(data?.car_style),
  ]);

  const advertisementDotListItems: string[] = reject(isEmpty, [
    i18n.t('general.labels.nDaysInAdvertisement', {count: data?.days_on_stock ?? 0}),
    translateSellerType(data?.seller?.type ?? 'SELLERTYPE_PRIVATE_SELLER') ?? '',
  ]);

  const yearDifference = getDifference(
    data?.year,
    props.priceReport.vehicle.firstRegistrationOnYear
  );

  const featureScoreDifference = getDifference(
    data?.feature_score ?? 0,
    props.priceReport.featuresLevel
  );

  const mileageDifference = getDifference(data?.mileage, props.priceReport.vehicle.mileage);

  const vehicleName = match([data?.ad_title, !!props.isMyVehicle])
    .with([Pattern.nonNullable, false], () => data?.ad_title)
    .otherwise(() => `${translatedVehicleInfo.make} ${translatedVehicleInfo.model}`);
  const vehiclePrice = getPrice(data, props.priceReport);
  const priceDifference = getDifference(
    vehiclePrice,
    props.priceReport.sellingPrice.recommended.value
  );
  const currency = props.priceReport.sellingPrice.recommended.currency;

  const lastOccurrence = data?.last_occurrence;

  return (
    <Box
      backgroundColor="general.white"
      borderRadius="medium"
      boxShadow={props.showShadow ? 'elevation_3' : undefined}
      border="1px solid"
      borderColor="palettes.neutral.40.100"
      overflow="hidden"
      minWidth={130}
    >
      <DataStatus isLoading={isLoading || isFetching} isError={isError} minHeight={34}>
        <HStack height="100%">
          <VehicleImage
            src={data?.thumbnail_url?.full}
            isSold={!!data?.is_sold}
            soldAt={isNotNil(lastOccurrence) ? parseDate(lastOccurrence) : null}
            isMyVehicle={false}
            isSuspicious={isTrue(data?.suspicious)}
            maxWidth={45}
            maxHeight={34}
          />
          <Box padding={4} paddingBottom={2} height={34}>
            <VStack justify="space-between" height="100%">
              <Box flexGrow={1}>
                <Text size="small" alternative>
                  {vehicleName}
                </Text>
                <Parameters
                  parameters={vehicleDotListItems}
                  color="secondary"
                  size="xSmall"
                  data-testid={suffixTestId('vehicleParameters', props)}
                />
                <Space vertical={1} />
                <Grid spacing={4} columns={4}>
                  <ComparedValue
                    value={data?.year ?? 0}
                    difference={yearDifference}
                    data-testid={suffixTestId('year', props)}
                  />
                  <ComparedValue
                    value={data?.feature_score ?? 0}
                    difference={featureScoreDifference}
                    rankValue={(data?.feature_score ?? 0) * 100}
                    formatter={(value) => formatPercentage(value, 'down')}
                    data-testid={suffixTestId('featureScore', props)}
                  />
                  <ComparedValue
                    value={data?.mileage ?? 0}
                    difference={mileageDifference}
                    formatter={(value) => `${formatNumber(value)} ${i18n.t('general.metric.km')}`}
                    isReverseColor
                    data-testid={suffixTestId('mileage', props)}
                  />
                  <ComparedValue
                    value={vehiclePrice}
                    difference={priceDifference}
                    firstScrape={data?.first_scraped_price_with_vat}
                    formatter={(value) =>
                      formatCurrency(
                        value,
                        mapMetadaCurrency(data?.first_scraped_price_currency) ?? currency
                      ) ?? ''
                    }
                    isReverseColor
                    isAlignEnd
                    data-testid={suffixTestId('price', props)}
                  />
                </Grid>
              </Box>
              <Parameters
                parameters={advertisementDotListItems}
                color="secondary"
                size="xSmall"
                data-testid={suffixTestId('advertisementVehicleParameters', props)}
              />
            </VStack>
          </Box>
        </HStack>
      </DataStatus>
    </Box>
  );
}
