import {getColorByPath, CSSDimension, Integer, Show, ThemeColorPath} from 'platform/foundation';
import styled, {css} from 'styled-components';

import {Children, isValidElement, ReactNode, useState} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {TableRowContext} from '../TableRowContext';
import {TableCellBaseProps} from '../types/TableCellBaseProps';
import {TableVariant} from '../types/TableVariant';
import {useTableContext} from '../useTableContext';
import {TableRowActions, TableRowActionsData} from './TableRowActions';

export interface TableRowProps extends TestIdProps {
  children?: ReactNode;
  actions?: TableRowActionsData;
  level?: number;
  actionsWidth?: CSSDimension | Integer;
  /**@deprecated use onRowClick or onCellClick instead */
  onClick?: () => void;
  color?: ThemeColorPath;
  hoverColor?: ThemeColorPath;
  onRowClick?: (event?: React.MouseEvent<HTMLTableRowElement>) => void;
  onCellClick?: (
    event?: React.MouseEvent<HTMLTableCellElement>,
    cellProps?: TableCellBaseProps
  ) => void;
}

export function TableRow(props: TableRowProps) {
  const context = useTableContext();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <TRStyled
      $variant={context.variant}
      $color={props.color}
      $hoverColor={props.hoverColor}
      onClick={props.onRowClick}
      data-testid={suffixTestId('tableRow', props)}
    >
      {Children.map(props.children, (child) => {
        if (!isValidElement(child)) {
          return;
        }

        const childProps = child.props as TableCellBaseProps;

        return (
          <td
            key={child.key}
            data-testid={suffixTestId('cell', props)}
            onMouseEnter={() => {
              if (childProps.cellHoverDisabled) {
                return setIsHovered(false);
              }
              setIsHovered(true);
            }}
            onMouseLeave={() => {
              if (childProps.cellHoverDisabled) {
                return setIsHovered(false);
              }
              setIsHovered(false);
            }}
            onClick={(event) => {
              props.onClick?.();

              if (props.onCellClick) {
                props.onCellClick(event, childProps);
              }
            }}
          >
            <TableRowContext value={{isHovered}}>{child}</TableRowContext>
          </td>
        );
      })}
      <Show when={props.actions}>
        <TableRowActions
          actions={props.actions}
          actionsWidth={props.actionsWidth}
          data-testid={props['data-testid']}
        />
      </Show>
    </TRStyled>
  );
}

interface TRStyledProps {
  $variant: TableVariant;
  $color?: ThemeColorPath;
  $hoverColor?: ThemeColorPath;
}

const TRStyled = styled.tr<TRStyledProps>(
  (props) => css`
    ${props.$variant === 'bordered' &&
    css`
      td {
        border-top: 1px solid ${props.theme.colors.general.separator};
      }

      &:first-child td {
        border-top: 0;
      }
    `}

    ${props.$variant === 'stripped' &&
    css`
      &:nth-child(even) td {
        background-color: ${getColorByPath(props.$color, props.theme) ??
        props.theme.colors.palettes.neutral[10][100]};
      }
    `}

  td {
      background-color: ${getColorByPath(props.$color, props.theme)};
      cursor: ${props.onClick ? 'pointer' : undefined};
    }

    &:hover {
      td {
        background-color: ${getColorByPath(props.$hoverColor, props.theme) ??
        props.theme.colors.palettes.neutral[20][100]};
      }
    }
  `
);
