import {ImageFit} from 'platform/foundation';

const RESIZE_ENABLED_SERVERS = [
  'storage.prod.alpha-analytics.dev', // Alpha dev server URL is the same as prod URL
  'storage.api.dev.omnetic.dev',
  'storage.api.sandbox.omnetic.dev',
  'storage.api.stage.omnetic.dev',
  'storage.api.omnetic.com',
] as const;

export const makeStorageUrl = (url: string, width: number, height: number, fit: ImageFit) => {
  try {
    const originalUrl = new URL(url, window.location.href);

    if (RESIZE_ENABLED_SERVERS.includes(originalUrl.hostname) && url.includes('/get/')) {
      const resizeUrl = new URL(url.replace('/get/', '/resize/'), window.location.href);
      resizeUrl.searchParams.set('width', width.toString());
      resizeUrl.searchParams.set('height', height.toString());
      resizeUrl.searchParams.set('fit', storageFitMap[fit]);
      return resizeUrl.toString();
    }

    return url;
  } catch {
    return url;
  }
};

const storageFitMap = {
  contain: 'inside',
  'scale-down': 'inside',
  cover: 'cover',
} as const;
