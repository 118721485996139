import {IconButton} from 'platform/components';
import {Hide, HStack, Show, Spinner} from 'platform/foundation';

import {isNil} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {usePriceReport} from '../../../../../hooks/usePriceReport';
import {LanguageSwitcher} from './LanguageSwitcher';

interface ActionsProps extends TestIdProps {
  isDownloadLinkLoading: boolean;
  onShare: () => void;
  onDownload: () => void;
}

export function Actions(props: ActionsProps) {
  const {priceReport} = usePriceReport();

  return (
    <HStack spacing={2}>
      <LanguageSwitcher data-testid={suffixTestId('header', props)} />
      <Hide when={isNil(priceReport)}>
        <Hide when={props.isDownloadLinkLoading}>
          <IconButton
            priority="primary"
            icon="file/download"
            onClick={props.onDownload}
            data-testid={suffixTestId('download', props)}
          />
        </Hide>
        <Show when={props.isDownloadLinkLoading}>
          <Spinner />
        </Show>
        <IconButton
          priority="primary"
          icon="social/share"
          onClick={props.onShare}
          data-testid={suffixTestId('share', props)}
        />
      </Hide>
    </HStack>
  );
}
