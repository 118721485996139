import {Button, DataStatus} from 'platform/components';
import {Box, HStack, Show} from 'platform/foundation';

import {ReactNode} from 'react';

import {i18n} from '@price-report/shared';

import {useFilter} from '../../hooks/FilterContext';
import {useHeaderHeight} from '../../hooks/useHeaderHeight';
import {getTestId} from '../../utils/getTestId';
import {Pagination, PaginationProps} from './Pagination';

interface MobilePaginationWithFilterBarProps extends PaginationProps {
  children?: ReactNode;
  isLoading?: boolean;
}

export function MobilePaginationWithFilterBar(props: MobilePaginationWithFilterBarProps) {
  const {openMobileFilter} = useFilter();
  const headerHeight = useHeaderHeight();

  return (
    <Show onMobile onTablet>
      <Box
        backgroundColor="general.white"
        borderBottom="1px solid"
        borderColor="palettes.neutral.40.100"
        padding={2}
        position="sticky"
        top={`calc(${headerHeight}px)`}
        zIndex="CONTENT"
        data-testid={getTestId('mobilePaginationWithFilterBar', 'wrapper')}
      >
        <HStack justify="space-between" align="center">
          <Button
            onClick={openMobileFilter}
            variant="ghost"
            leftIcon="content/filter_list"
            title={i18n.t('page.advertisiments.filter')}
            data-testid={getTestId('mobilePaginationWithFilterBar', 'filterButton')}
          />
          <HStack spacing={2}>
            <DataStatus spinnerSize="small" isLoading={props.isLoading}>
              <Pagination {...props} />
            </DataStatus>
            {props.children}
          </HStack>
        </HStack>
      </Box>
    </Show>
  );
}
