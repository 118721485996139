import {useEffect} from 'react';
import TagManager from 'react-gtm-module';

import {isNil, isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {environment} from '@price-report/environment';

import {useRequiredParams} from 'shared';

import {useGetPriceReportQuery, useGetRequestQuery} from '../store/priceReportApi';

export function usePriceReport() {
  const {requestId} = useRequiredParams();
  const {
    data: request,
    isLoading: isRequestLoading,
    isError: isRequestError,
    error: requestError,
  } = useGetRequestQuery({id: requestId});
  const {
    data: priceReport,
    isLoading: isReportLoading,
    isError: isReportError,
    error: reportError,
  } = useGetPriceReportQuery(
    {reportId: request?.idPriceReport ?? ''},
    {skip: isNilOrEmpty(request?.idPriceReport)}
  );

  const isLoading = isRequestLoading || isReportLoading;
  const isError = isRequestError || isReportError;
  const isNotFound =
    isNotNil(requestError) && 'status' in requestError && requestError.status === 404;
  const isNotCreated =
    !isLoading && !isRequestError && !isNotFound && isNilOrEmpty(request?.idPriceReport);
  const isExpired = isNotNil(reportError) && 'status' in reportError && reportError.status === 410;

  useEffect(() => {
    if (
      isNil(priceReport?.id) ||
      isNilOrEmpty(environment.GTM_CONTAINER_ID) ||
      environment.ENV_TYPE !== 'prod'
    ) {
      return;
    }

    const dataLayer = {
      environment: environment.ENV_TYPE,
      priceReportId: priceReport!.id ?? '',
    };

    TagManager.dataLayer({dataLayer});
  }, [priceReport, priceReport?.id]);

  return {
    isNotCreated,
    isNotFound,
    isExpired,
    isLoading,
    isError,
    priceReport,
    requestData: request,
  };
}
