import {match} from 'ts-pattern';

import {isNil, isNotNil} from 'ramda';

import {TooltipHorizontalPosition} from '../types/TooltipHorizontalPosition';
import {TooltipVerticalPosition} from '../types/TooltipVerticalPosition';

export const getTooltipPosition = (
  map: google.maps.Map | null,
  location: google.maps.LatLngLiteral
): {vertical: TooltipVerticalPosition; horizontal: TooltipHorizontalPosition} => {
  const bounds = map?.getBounds();
  const center = bounds?.getCenter();
  const isInUpperHalf = isNil(center) || location.lat > center.lat();
  const vertical = isInUpperHalf ? 'bottom' : 'top';

  const west = bounds?.getSouthWest().lng();
  const east = bounds?.getNorthEast().lng();
  const isInLeftThird = isNotNil(west) && isNotNil(east) && location.lng < west + (east - west) / 3;
  const isInRightThird =
    isNotNil(west) && isNotNil(east) && location.lng > east - (east - west) / 3;
  const horizontal = match<[boolean, boolean], TooltipHorizontalPosition>([
    isInLeftThird,
    isInRightThird,
  ])
    .with([true, false], () => 'right')
    .with([false, true], () => 'left')
    .otherwise(() => 'center');

  return {
    vertical,
    horizontal,
  };
};
