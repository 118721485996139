import {showNotification} from 'platform/components';
import {Box, Hide, HStack, Show} from 'platform/foundation';

import {isNil} from 'ramda';

import {i18n} from '@price-report/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {useDownloadPDF} from '../../../../hooks/useDownloadPDF';
import {usePriceReport} from '../../../../hooks/usePriceReport';
import {useIsPrintForced} from '../../../../pages/Overview/hooks/useIsPrintForced';
import {Actions} from './components/Actions';
import {HorizontalScrollWithGradient} from './components/HorizontalScrollWithGradient';
import {Logo} from './components/Logo';
import {Navigation} from './components/Navigation';
import {ReportInfo} from './components/ReportInfo';

export function Header(props: TestIdProps) {
  const {priceReport, requestData} = usePriceReport();
  const {priceReportName, handleDownload, isPreparingDownload} = useDownloadPDF();
  const {isPrintForced, isPrint, getResponsiveValue} = useIsPrintForced();

  const handleShare = async () => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share#browser_compatibility
    // Some browsers don't support navigator.share yet – in such case we want to copy the URL to clipboard
    if (navigator.share) {
      try {
        await navigator.share({
          title: priceReportName,
          url: window.location.href,
        });
      } catch (err: unknown) {
        // Cancelling share action throws an AbortError – we want to ignore that one
        if (!(err instanceof Error && err.toString().includes('AbortError'))) {
          showNotification.error(i18n.t('general.notifications.shareError'));
        }
      }
    } else {
      await navigator.clipboard.writeText(window.location.href);
      showNotification.info(i18n.t('general.notifications.copiedToClipboard'));
    }
  };

  const headerRightSide = (
    <HStack align="center" spacing={4}>
      <ReportInfo
        data-testid={suffixTestId('header', props)}
        created={priceReport?.created}
        market={priceReport?.market ?? requestData?.market}
        couponNumber={priceReport?.couponNumber}
      />
      <Hide when={isPrint}>
        <Actions
          onDownload={handleDownload}
          onShare={handleShare}
          isDownloadLinkLoading={isPreparingDownload}
          data-testid={suffixTestId('actions', props)}
        />
      </Hide>
    </HStack>
  );

  return (
    <Box
      backgroundColor="palettes.blue.70.100"
      position={getResponsiveValue('sticky', 'sticky', 'sticky', 'sticky', 'relative')}
      top={0}
      zIndex="HEADER"
      data-testid={suffixTestId('wrapper', props)}
    >
      <Show onMobile when={!isPrintForced}>
        <Box paddingHorizontal={3}>
          <HStack spacing={4} justify="space-between" align="center">
            <Logo data-testid={suffixTestId('logo', props)} />
            {headerRightSide}
          </HStack>
        </Box>
        <HorizontalScrollWithGradient paddingHorizontal={3}>
          <Navigation shouldScrollActiveLinkIntoView isLinksDisabled={isNil(priceReport)} />
        </HorizontalScrollWithGradient>
      </Show>
      <Hide onMobile>
        <Box paddingHorizontal={6}>
          <HStack justify="space-between" wrap spacing={3} align="center">
            <HStack spacing={12} wrap align="center">
              <Logo data-testid={suffixTestId('logo', props)} />
              <Hide when={isPrint}>
                <Navigation isLinksDisabled={!priceReport} />
              </Hide>
            </HStack>
            {headerRightSide}
          </HStack>
        </Box>
      </Hide>
    </Box>
  );
}
