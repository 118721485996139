import {Search as PlatformSearch} from 'platform/components';

import {useCallback} from 'react';
import {useController, UseControllerProps} from 'react-hook-form';

import {isNotNil} from 'ramda';

import {FilterFormData} from '@price-report/shared';

interface SearchProps extends UseControllerProps<FilterFormData> {
  placeholder: string;
}

export function Search(props: SearchProps) {
  const {
    field: {value, onChange},
  } = useController(props);

  const handleClear = useCallback(() => {
    onChange(null);
  }, [onChange]);

  return (
    <PlatformSearch
      value={isNotNil(value) ? value.toString() : null}
      placeholder={props.placeholder}
      onChange={onChange}
      onClear={handleClear}
    />
  );
}
