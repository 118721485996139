import {isNotEmpty, isNotNil} from 'ramda';

import {FilterFormData} from '@price-report/shared';

export const simpleFilterKeys: (keyof FilterFormData)[] = [
  'radius',
  'title',
  'showSoldCars',
  'showStockCars',
  'priceGte',
  'priceLte',
  'isVatDeductible',
  'firstRegistrationOnYearGte',
  'firstRegistrationOnYearLte',
  'powerGte',
  'powerLte',
  'mileageGte',
  'mileageLte',
  'featureScoreGte',
  'featureScoreLte',
  'drive',
  'transmission',
  'bodyStyle',
  'fuelType',
];

export const parseFilterFormDataToSearchParams = (
  currentSearchParams: URLSearchParams,
  filterFormData: FilterFormData | null
): URLSearchParams => {
  const params = new URLSearchParams(currentSearchParams);
  if (isNotNil(filterFormData?.address) && isNotEmpty(filterFormData.address)) {
    params.set('label', filterFormData.address.label);
    params.set('lat', filterFormData.address.lat.toString());
    params.set('lng', filterFormData.address.lng.toString());
  } else {
    params.delete('label');
    params.delete('lat');
    params.delete('lng');
  }
  simpleFilterKeys.forEach((key) => {
    if (isNotNil(filterFormData?.[key]) && isNotEmpty(filterFormData[key])) {
      params.set(key, filterFormData[key].toString());
    } else {
      params.delete(key);
    }
  });
  return params;
};
