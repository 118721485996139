import {Button} from 'platform/components';
import {VStack} from 'platform/foundation';

import {i18n} from '@price-report/shared';

import {suffixTestId} from 'shared';

import {useFilter} from '../../../hooks/FilterContext';
import {Filter, FilterProps} from '../../Filter/Filter';
import {Modal} from '../../Modal/Modal';

interface FilterModalProps extends FilterProps {
  onReset: () => void;
}

export function FilterModal(props: FilterModalProps) {
  const context = useFilter();

  return (
    <Modal
      heading={i18n.t('page.advertisiments.filter')}
      footer={
        <VStack spacing={4}>
          <Button
            onClick={props.onReset}
            variant="secondary"
            leftIcon="navigation/refresh"
            title={i18n.t('entity.filterForm.restore')}
            isFullWidth
            data-testid={suffixTestId('resetButton', props)}
          />
          <Button
            onClick={context.closeMobileFilter}
            variant="primary"
            leftIcon="content/filter_list"
            title={i18n.t('entity.filterForm.filter')}
            isFullWidth
            data-testid={suffixTestId('confirmButton', props)}
          />
        </VStack>
      }
      isOpen={context.isOpenMobileFilter}
      onClose={context.closeMobileFilter}
      data-testid={suffixTestId('filterModal', props)}
    >
      <Filter
        priceReport={props.priceReport}
        formControl={props.formControl}
        formApi={props.formApi}
        data-testid={suffixTestId('filter', props)}
      />
    </Modal>
  );
}
