import styled from 'styled-components';

import {isInCypress} from '../../utils/isInCypress';

interface ScrollWithHiddenScrollbarInCypressProps {
  $flex?: number;
}

// Hide scrollbar, so snapshot tests won't fail (scrollbar doesn't look the same on different platforms)
export const ScrollWithHiddenScrollbarInCypress = styled.div<ScrollWithHiddenScrollbarInCypressProps>`
  overflow: auto;
  ${({$flex}) => $flex && `flex: ${$flex};`}

  ${() =>
    isInCypress &&
    `
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* Edge */

      &::-webkit-scrollbar { /* Chrome, Safari and Opera */
        display: none;
      }
  `}
`;
