import {isNotEmpty} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {i18n} from '../i18n/i18n';
import {FiltersErrors} from '../types/api/api';
import {FilterFormData} from '../types/FilterFormData';

export const validateFilterValues = (values: FilterFormData): FiltersErrors | null => {
  let errors = {};
  if (isNotNil(values.radius) && values.radius < 0) {
    errors = {...errors, radius: i18n.t('entity.filterForm.error.radius.positive')};
  }
  if (isNotNil(values.radius) && !Number.isInteger(values.radius)) {
    errors = {...errors, radius: i18n.t('entity.filterForm.error.radius.integer')};
  }

  if (
    (isNotNil(values.priceGte) && values.priceGte < 0) ||
    (isNotNil(values.priceLte) && values.priceLte < 0)
  ) {
    errors = {...errors, price: i18n.t('entity.filterForm.error.price.positive')};
  }
  if (
    (isNotNil(values.priceGte) && !Number.isInteger(values.priceGte)) ||
    (isNotNil(values.priceLte) && !Number.isInteger(values.priceLte))
  ) {
    errors = {...errors, price: i18n.t('entity.filterForm.error.price.integer')};
  }
  if (isNotNil(values.priceGte) && isNotNil(values.priceLte) && values.priceGte > values.priceLte) {
    errors = {...errors, price: i18n.t('entity.filterForm.error.price.comparison')};
  }

  if (
    (isNotNil(values.mileageGte) && values.mileageGte < 0) ||
    (isNotNil(values.mileageLte) && values.mileageLte < 0)
  ) {
    errors = {...errors, mileage: i18n.t('entity.filterForm.error.mileage.positive')};
  }
  if (
    (isNotNil(values.mileageGte) && !Number.isInteger(values.mileageGte)) ||
    (isNotNil(values.mileageLte) && !Number.isInteger(values.mileageLte))
  ) {
    errors = {...errors, mileage: i18n.t('entity.filterForm.error.mileage.integer')};
  }
  if (
    isNotNil(values.mileageGte) &&
    isNotNil(values.mileageLte) &&
    values.mileageGte > values.mileageLte
  ) {
    errors = {...errors, mileage: i18n.t('entity.filterForm.error.mileage.comparison')};
  }

  if (
    (isNotNil(values.powerGte) && values.powerGte < 0) ||
    (isNotNil(values.powerLte) && values.powerLte < 0)
  ) {
    errors = {...errors, power: i18n.t('entity.filterForm.error.power.positive')};
  }
  if (
    (isNotNil(values.powerGte) && !Number.isInteger(values.powerGte)) ||
    (isNotNil(values.powerLte) && !Number.isInteger(values.powerLte))
  ) {
    errors = {...errors, power: i18n.t('entity.filterForm.error.power.integer')};
  }
  if (isNotNil(values.powerGte) && isNotNil(values.powerLte) && values.powerGte > values.powerLte) {
    errors = {...errors, power: i18n.t('entity.filterForm.error.power.comparison')};
  }

  if (
    (isNotNil(values.featureScoreGte) && values.featureScoreGte < 0) ||
    (isNotNil(values.featureScoreLte) && values.featureScoreLte < 0)
  ) {
    errors = {...errors, featureScore: i18n.t('entity.filterForm.error.featureScore.positive')};
  }
  if (
    isNotNil(values.featureScoreGte) &&
    isNotNil(values.featureScoreLte) &&
    values.featureScoreGte > values.featureScoreLte
  ) {
    errors = {...errors, featureScore: i18n.t('entity.filterForm.error.featureScore.comparison')};
  }

  return isNotEmpty(errors) ? errors : null;
};
