// eslint-disable-next-line eag/no-external-date-time-format-functions -- TODO [T20-67958]
import {format, isAfter, isBefore, isSameMonth} from 'date-fns';
import {AppLanguage, convertStringLocaleToDateFns} from 'platform/locale';

import {memoizeWith, range} from 'ramda';

import {capitalize, Nullish} from 'shared';

import {OptionType} from '../../../types/FormControlProps';

function getMonthChoiceOptions(
  activeYear: number,
  minDate: Date | Nullish,
  maxDate: Date | Nullish,
  currentLanguage: AppLanguage
): OptionType[] {
  const options: OptionType[] = [];

  range(0, 12).forEach((i) => {
    const date = new Date(activeYear, i, 1);

    const isDisabledByMinDate = minDate && isBefore(date, minDate) && !isSameMonth(date, minDate);
    const isDisabledMyMaxDate = maxDate && isAfter(date, maxDate) && !isSameMonth(date, maxDate);

    if (isDisabledByMinDate || isDisabledMyMaxDate) {
      return;
    }

    options.push({
      label: capitalize(
        format(date, 'LLLL', {
          locale: convertStringLocaleToDateFns(currentLanguage),
        })
      ),
      value: `${i}`,
    });
  });

  return options;
}

export const getMemoizedMonthChoiceOptions = memoizeWith(
  (activeYear, minDate, maxDate, currentLanguage) =>
    [
      activeYear,
      minDate ? format(minDate, 'y-M-d') : '',
      maxDate ? format(maxDate, 'y-M-d') : '',
      currentLanguage,
    ].join('-'),
  getMonthChoiceOptions
);
