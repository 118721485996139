import {Box, Clickable, HStack, Icon, Text} from 'platform/foundation';
import {AppLanguage} from 'platform/locale';
import styled from 'styled-components';

import {FALLBACK_LANGUAGE, i18n} from '@price-report/shared';

import {suffixTestId, TestIdProps, useBoolean, useQueryState} from 'shared';

import {languageDialogItems} from '../../../../../constants/languageDialogItems';
import {queryParams} from '../../../../../constants/queryParams';
import {LanguageDialogModal} from './LanguageDialogModal';

export function LanguageSwitcher(props: TestIdProps) {
  const [isVisible, open, close] = useBoolean(false);
  const [, setLanguage] = useQueryState(queryParams.LANG);

  const onChangeLanguage = (lang: AppLanguage) => {
    i18n
      .changeLanguage(lang)
      .then(() => {
        setLanguage(lang);
      })
      .catch(() => {
        throw new Error('Failed to set the application language');
      });
  };

  const flagIcon =
    languageDialogItems[(i18n?.resolvedLanguage ?? FALLBACK_LANGUAGE) as AppLanguage].icon;

  return (
    <StyledWrapper>
      <Box height={8} paddingHorizontal={2}>
        <Clickable height="100%" onClick={open}>
          <HStack height="100%" align="center" justify="center" spacing={2}>
            <Icon value={flagIcon} size={7} />
            <Text color="white" size="small">
              {i18n?.resolvedLanguage ?? FALLBACK_LANGUAGE}
            </Text>
          </HStack>
        </Clickable>
        <LanguageDialogModal
          isOpen={isVisible}
          onChoseLanguage={onChangeLanguage}
          onClose={close}
          data-testid={suffixTestId('languageSwitcher', props)}
        />
      </Box>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  border-radius: ${({theme}) => theme.radii.small};

  &:hover {
    background-color: ${({theme}) => theme.colors.general.hover};
  }
`;
