import {foundationTheme} from 'platform/foundation';

const fonts = {
  body: 'Roboto, sans-serif',
  heading: 'EuclidCircularB, Roboto, sans-serif',
};

const headingFontSizes = {
  1: '1.75rem',
  2: '1.5rem',
  3: '1.3125rem',
  4: '1rem',
  5: '0.875rem',
  6: '0.75rem',
};

const fontSizes = {
  display: {
    printer: {1: '2.5rem'},
    desktop: {1: '2.5rem'},
    notebook: {1: '2.5rem'},
    tablet: {1: '2.5rem'},
    mobile: {1: '2.5rem'},
  },
  heading: {
    printer: headingFontSizes,
    desktop: headingFontSizes,
    notebook: headingFontSizes,
    tablet: headingFontSizes,
    mobile: headingFontSizes,
  },
  text: {
    large: '1.125rem',
    base: '1rem',
    small: '0.875rem',
    xSmall: '0.75rem',
    xxSmall: '0.625rem',
  },
};

const fontWeights = {
  regular: 400,
  medium: 500,
  bold: 700,
  display: {
    default: 700,
    alternative: 400,
  },
  heading: {
    default: 700,
    alternative: 400,
  },
  text: {
    default: 400,
    alternative: 700,
  },
};

const shadows = {
  focus: '0 0 0 4px #E9EBF8',
  active: '0 0 0 4px #E9EBF8',
};

const palettes = {
  blue: {
    10: {
      100: '#EBEDFE',
    },
    20: {
      100: '#D8DBFD',
    },
    30: {
      100: '#B3B8FB',
    },
    40: {
      100: '#B3B8FB',
    },
    50: {
      100: '#6C73EA',
    },
    60: {
      100: '#3E47DD',
    },
    70: {
      100: '#262A98',
    },
    80: {
      100: '#1D2176',
    },
    90: {
      100: '#131762',
    },
  },
  teal: {
    10: {
      100: '#F7FFFC',
    },
    20: {
      100: '#DDFEF0',
    },
    30: {
      100: '#C7FFE9',
    },
    40: {
      100: '#BEF6E0',
    },
    50: {
      100: '#9FCCBA',
    },
    60: {
      100: '#86B6A3',
    },
    70: {
      100: '#77998C',
    },
    80: {
      100: '#5A7369',
    },
    90: {
      100: '#465952',
    },
  },
  green: {
    10: {
      100: '#F2FFF5',
    },
    20: {
      100: '#DEFBE6',
    },
    30: {
      100: '#A7F0BA',
    },
    40: {
      100: '#6FDC8C',
    },
    50: {
      100: '#42BE65',
    },
    60: {
      100: '#24A148',
    },
    70: {
      100: '#198038',
    },
    80: {
      100: '#0E6027',
    },
    90: {
      100: '#064419',
    },
  },
  yellow: {
    10: {
      100: '#FDF8EA',
    },
    20: {
      100: '#FFF5D9',
    },
    30: {
      100: '#FFF2C8',
    },
    40: {
      100: '#FFECAE',
    },
    50: {
      100: '#FFE597',
    },
    60: {
      100: '#F7D466',
    },
    70: {
      100: '#DDB638',
    },
    80: {
      100: '#AC902D',
    },
    90: {
      100: '#7A6629',
    },
  },
};

const general = {
  accent: palettes.blue[60][100],
  hover: '#3237B7',
};

const text = {
  link: palettes.blue[60][100],
};

const breakpoints = {
  notebook: 1366,
  tablet: 1152,
  mobile: 768,
} as const;

const devices = {
  desktop: `(min-width: ${breakpoints.notebook}px)`,
  notebook: `(min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.notebook}px)`,
  tablet: `(min-width: ${breakpoints.mobile}px) and (max-width: ${breakpoints.tablet}px)`,
  mobile: `(max-width: ${breakpoints.mobile}px)`,
  printer: 'print',
} as const;

export const theme = {
  fonts,
  fontWeights,
  fontSizes,
  devices,
  shadows,
  colors: {
    palettes,
    general,
    text,
  },
  components: {
    Card: {
      elevation: 'elevation_3',
      borderRadius: 'medium',
    },
    Choice: {
      backgroundColor: 'general.accent',
    },
    Switch: {
      trackStyle: {backgroundColor: foundationTheme.colors.palettes.neutral[30][100]},
    },
    Button: {
      variants: {
        ghost: {
          hover: {
            backgroundColor: 'palettes.neutral.30.100',
          },
          active: {
            backgroundColor: '#262435',
          },
        },
        link: {
          color: 'text.link',
        },
      },
    },
  },
} as const;
