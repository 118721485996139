import {AdvancedMarker, Pin, useMap} from '@vis.gl/react-google-maps';
import {FlexboxJustify, HStack, Show, VStack} from 'platform/foundation';
import styled, {useTheme} from 'styled-components';
import {match} from 'ts-pattern';

import {useState, useEffect, memo} from 'react';

import {PriceReportType} from '@price-report/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {ExtendedStatisticsCar} from '../types';
import {TooltipHorizontalPosition} from '../types/TooltipHorizontalPosition';
import {TooltipVerticalPosition} from '../types/TooltipVerticalPosition';
import {getChartItemColor} from '../utils/getChartItemColor';
import {getTooltipPosition} from '../utils/getTooltipPosition';
import {CarTooltip} from './CarTooltip';

const PIN_HEIGHT = 38;
const BORDER_HEIGHT = 16;
const TRIANGLE_SIZE = 3;

interface MarkerWithInfoProps extends RequiredTestIdProps {
  location: google.maps.LatLngLiteral;
  cars: ExtendedStatisticsCar[];
  priceReport: PriceReportType;
  isFocused: boolean;
  isSelected: boolean;
  onClick: (cars: ExtendedStatisticsCar[]) => void;
  onHover: () => void;
}

export const MarkerWithInfo = memo((props: MarkerWithInfoProps) => {
  const map = useMap();
  const theme = useTheme();
  const color = getChartItemColor(theme, props.cars[0], props.isFocused, props.isSelected);
  const [tooltipPosition, setTooltipPosition] = useState<{
    vertical: TooltipVerticalPosition;
    horizontal: TooltipHorizontalPosition;
  }>({vertical: 'bottom', horizontal: 'right'});
  const [maxTooltipHeight, setMaxTooltipHeight] = useState<number | undefined>();

  useEffect(() => {
    if (props.isFocused) {
      const position = getTooltipPosition(map, props.location);
      setTooltipPosition(position);

      const mapDiv = map?.getDiv();
      const mapHeight = mapDiv?.clientHeight ?? document.documentElement.clientHeight;
      setMaxTooltipHeight(
        Math.floor(mapHeight / 2) - BORDER_HEIGHT - (position.vertical === 'top' ? 0 : PIN_HEIGHT)
      );
    }
  }, [map, props.location, props.isFocused]);

  const hstackJustify = match<TooltipHorizontalPosition, FlexboxJustify>(tooltipPosition.horizontal)
    .with('left', () => 'flex-end')
    .with('right', () => 'flex-start')
    .otherwise(() => 'center');

  return (
    <>
      {/* Prevent firing onMousemove on GoogleMap, so that the tooltip stays visible while hovering over the marker */}
      <div onMouseMove={(event) => event.stopPropagation()}>
        <AdvancedMarker
          position={props.location}
          zIndex={props.isFocused ? 1 : undefined}
          onClick={() => props.onClick(props.cars)}
          onMouseEnter={props.onHover}
        >
          <Pin
            glyph={props.cars.length.toString()}
            background={color}
            glyphColor="#000"
            borderColor="#000"
          />
        </AdvancedMarker>
      </div>

      <Show when={props.isFocused}>
        <AdvancedMarker position={props.location}>
          <CustomTooltip
            $verticalPosition={tooltipPosition.vertical}
            $horizontalPosition={tooltipPosition.horizontal}
          >
            <Show when={tooltipPosition.vertical === 'bottom'}>
              <HStack justify={hstackJustify}>
                <Triangle $position="bottom" onMouseMove={(event) => event.stopPropagation()} />
              </HStack>
            </Show>
            <CustomTooltipContent
              /* Prevent firing scroll events, so that no overlay is displayed */
              onMouseMove={(event) => event.stopPropagation()}
              onWheel={(event) => event.stopPropagation()}
              onScroll={(event) => event.stopPropagation()}
              $maxHeight={maxTooltipHeight}
              $horizontalPosition={tooltipPosition.horizontal}
              $verticalPosition={tooltipPosition.vertical}
              data-testid={suffixTestId('customTooltip', props)}
            >
              <VStack spacing={2}>
                {props.cars.map((car) => (
                  <CarTooltip
                    key={car.ad_id}
                    adId={car.ad_id}
                    isMyVehicle={car.isMyVehicle}
                    priceReport={props.priceReport}
                    data-testid={suffixTestId('carTooltip', props)}
                  />
                ))}
              </VStack>
            </CustomTooltipContent>
            <Show when={tooltipPosition.vertical === 'top'}>
              <HStack justify={hstackJustify}>
                <Triangle $position="top" onMouseMove={(event) => event.stopPropagation()} />
              </HStack>
            </Show>
          </CustomTooltip>
        </AdvancedMarker>
      </Show>
    </>
  );
});

const CustomTooltip = styled.div<{
  $verticalPosition: TooltipVerticalPosition;
  $horizontalPosition: TooltipHorizontalPosition;
}>`
  position: absolute;
  bottom: ${({$verticalPosition}) => ($verticalPosition === 'top' ? `${PIN_HEIGHT}px` : undefined)};
  top: ${({$verticalPosition}) => ($verticalPosition === 'bottom' ? 0 : undefined)};
  left: ${({theme, $horizontalPosition}) =>
    match($horizontalPosition)
      .with('center', () => '50%')
      .with('left', () => 'unset')
      .with('right', () => `calc(${theme.getSize(TRIANGLE_SIZE)}*-1)`)
      .exhaustive()};
  right: ${({theme, $horizontalPosition}) =>
    match($horizontalPosition)
      .with('center', () => 'unset')
      .with('left', () => `calc(${theme.getSize(TRIANGLE_SIZE)}*-1)`)
      .with('right', () => 'unset')
      .exhaustive()};
  transform: ${({$horizontalPosition}) =>
    match($horizontalPosition)
      .with('center', () => 'translateX(-50%)')
      .with('left', () => 'none')
      .with('right', () => 'none')
      .exhaustive()};
`;

const CustomTooltipContent = styled.div<{
  $maxHeight?: number;
  $horizontalPosition: TooltipHorizontalPosition;
  $verticalPosition: TooltipVerticalPosition;
}>`
  background-color: ${({theme}) => theme.colors.general.white};
  border-radius: ${({theme}) => theme.radii.medium};
  ${({$verticalPosition, $horizontalPosition}) =>
    match([$verticalPosition, $horizontalPosition])
      .with(['top', 'left'], () => 'border-bottom-right-radius: 0;')
      .with(['top', 'right'], () => 'border-bottom-left-radius: 0;')
      .with(['bottom', 'left'], () => 'border-top-right-radius: 0;')
      .with(['bottom', 'right'], () => 'border-top-left-radius: 0;')
      .otherwise(() => '')}
  box-shadow: ${({theme}) => theme.shadows.elevation_3};
  overflow: auto;
  max-height: ${({$maxHeight}) => ($maxHeight ? `${$maxHeight}px` : 'none')};
`;

const Triangle = styled.div<{$position: 'top' | 'bottom'}>`
  width: 0;
  height: 0;
  border-left: ${({theme}) => theme.getSize(TRIANGLE_SIZE)} solid transparent;
  border-right: ${({theme}) => theme.getSize(TRIANGLE_SIZE)} solid transparent;
  border-${({$position}) => $position}: ${({theme}) => theme.getSize(3)} solid white;
`;
