import TagManager from 'react-gtm-module';

import {isNil, isEmpty} from 'ramda';

import {environment} from '@price-report/environment';

export function gtmInit() {
  if (
    isNil(environment.GTM_CONTAINER_ID) ||
    isEmpty(environment.GTM_CONTAINER_ID) ||
    environment.ENV_TYPE !== 'prod'
  ) {
    return;
  }
  TagManager.initialize({
    gtmId: environment.GTM_CONTAINER_ID,
  });
}
