import {ThemeIconKey, Show} from 'platform/foundation';

import {Fragment, MouseEventHandler} from 'react';

import {isNotNil} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {Button, ButtonProps} from '../../Button/Button';
import {DropdownItem, DropdownItemProps} from '../../Dropdown/components/DropdownItem';
import {Dropdown} from '../../Dropdown/Dropdown';
import {DropdownProps} from '../../Dropdown/types/DropdownProps';
import {IconButton, IconButtonProps} from '../../IconButton/IconButton';
import {Separator} from '../../Separator/Separator';
import {Tooltip, TooltipProps} from '../../Tooltip/Tooltip';

export type DropdownActionMenuItem = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  hasSeparator?: boolean;
  tooltip?: Omit<TooltipProps, 'children'>;
} & Omit<DropdownItemProps, 'prefix' | 'suffix'>;

type DropdownBaseProps = {
  menuItems: DropdownActionMenuItem[];
} & Pick<DropdownProps, 'boundary' | 'strategy' | 'placement'> &
  TestIdProps;

type IconButtonBaseProps = {
  type: 'dropdown-iconButton';
  icon?: ThemeIconKey;
} & Pick<IconButtonProps, 'priority' | 'severity' | 'isDisabled' | 'size'> &
  DropdownBaseProps;

type ButtonBaseProps = {
  type: 'dropdown-button';
} & Pick<
  ButtonProps,
  'variant' | 'isLoading' | 'isDisabled' | 'size' | 'title' | 'leftIcon' | 'rightIcon'
> &
  DropdownBaseProps;

export type DropdownActionProps = IconButtonBaseProps | ButtonBaseProps;

function DownloadAction(props: DropdownActionProps) {
  return (
    <Dropdown
      onButtonClick={(event) => event.stopPropagation()}
      isLazy
      closeOnSelect
      lazyBehavior="unmount"
      placement={props.placement ?? 'bottom-end'}
      strategy={props.strategy ?? 'fixed'}
      boundary={props.boundary ?? 'scrollParent'}
      dropdownControl={
        props.type === 'dropdown-iconButton' ? (
          <IconButton
            icon={props.icon ?? 'navigation/more_vert'}
            size={props.size}
            priority={props.priority}
            severity={props.severity}
            data-testid={props['data-testid']}
            aria-label="header-dropdown-actions-icon"
          />
        ) : (
          <Button
            isLoading={props.isLoading}
            isDisabled={props.isDisabled}
            title={props.title}
            size={props.size}
            variant={props.variant}
            leftIcon={props.leftIcon}
            rightIcon={props.rightIcon}
            data-testid={props['data-testid']}
          />
        )
      }
      data-testid={props['data-testid']}
    >
      {props.menuItems.map((menuItem, index) => {
        const testId = menuItem['data-testid'] ?? `[${index}]`;

        const item = (
          <DropdownItem
            label={menuItem.label}
            leftIcon={menuItem.leftIcon}
            isDisabled={menuItem.isDisabled}
            isLoading={menuItem.isLoading}
            onClick={(event) => {
              event.stopPropagation();
              menuItem.onClick(event);
            }}
            severity={menuItem.severity}
            data-testid={suffixTestId(`menuItem-${testId}`, props)}
          />
        );

        return (
          <Fragment key={`dropdownActionItem-${menuItem.label}`}>
            {isNotNil(menuItem.tooltip) ? <Tooltip {...menuItem.tooltip}>{item}</Tooltip> : item}
            <Show when={menuItem.hasSeparator}>
              <Separator spacing={2} />
            </Show>
          </Fragment>
        );
      })}
    </Dropdown>
  );
}

export const DropdownAction = DownloadAction;
