import {
  CheckboxTree,
  CheckboxTreeOptionType,
  FormControl,
  Search,
  Separator,
} from 'platform/components';
import {Box, Stack, VStack} from 'platform/foundation';

import {useState} from 'react';
import {useController} from 'react-hook-form';

import {append, sum} from 'ramda';
import {ceil, isNilOrEmpty} from 'ramda-adjunct';

import {GetAllEnumsResponse} from '@price-report/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {useCatalogue} from '../../../hooks/useCatalogue';
import {VehicleFormType} from '../types/VehicleFormType';

interface AllFeaturesFiledProps extends TestIdProps {
  control: FormControl<VehicleFormType>;
}

export function AllFeaturesFiled(props: AllFeaturesFiledProps) {
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const {field} = useController({
    name: 'features',
    control: props.control,
  });
  const {features} = useCatalogue();

  const filteredFeatures = searchValue
    ? features.filter((feature) =>
        feature.labels[0]?.label.toLocaleUpperCase().includes(searchValue.toLocaleUpperCase())
      )
    : features;

  const treeOptions = getTreeOptions(getFilteredFeaturesWithParents(filteredFeatures, features));

  const [firstOptionsRow, secondOptionsRow] = getRowOptions(treeOptions);

  return (
    <VStack spacing={6} width="100%">
      <Separator spacing={0} />
      <Search
        value={searchValue}
        onChange={setSearchValue}
        data-testid={suffixTestId('featureSearch', props)}
      />
      <Stack direction={['column', 'column', 'row', 'row']} spacing={[2, 2, 4, 4]}>
        <Box flex={1}>
          <CheckboxTree
            key={searchValue}
            value={field.value}
            onChange={(value) => field.onChange(value ?? [])}
            options={firstOptionsRow}
            data-testid={suffixTestId('firstOptionsRow', props)}
          />
        </Box>
        <Box flex={1}>
          <CheckboxTree
            key={searchValue}
            value={field.value}
            onChange={(value) => field.onChange(value ?? [])}
            options={secondOptionsRow}
            data-testid={suffixTestId('secondOptionsRow', props)}
          />
        </Box>
      </Stack>
    </VStack>
  );
}

const getOptionsCount = (items: CheckboxTreeOptionType[]): number =>
  sum(items.map((item) => (item.options?.length ? getOptionsCount(item.options) + 1 : 1)));

const getRowOptions = (items: CheckboxTreeOptionType[]) => {
  const columnOptionsCount = ceil(getOptionsCount(items) / 2);

  let firstRow: CheckboxTreeOptionType[] = [];
  let secondRow: CheckboxTreeOptionType[] = [];
  let currentCount = 0;

  items.forEach((item) => {
    if (currentCount < columnOptionsCount) {
      firstRow = append(item, firstRow);
    } else {
      secondRow = append(item, secondRow);
    }
    currentCount = currentCount + 1 + getOptionsCount(item.options ?? []);
  });

  return [firstRow, secondRow] as const;
};

const getFilteredFeaturesWithParents = (
  items: GetAllEnumsResponse[number]['keys'],
  allItems: GetAllEnumsResponse[number]['keys']
): GetAllEnumsResponse[number]['keys'] => {
  if (isNilOrEmpty(items)) {
    return [];
  }

  const missingParent = items.filter(
    (item) => !items.some((otherItem) => otherItem.const_key === item.parent)
  );
  const missingParentItems = allItems.filter((item) =>
    missingParent.some((otherItem) => item.const_key === otherItem.parent)
  );

  return items.concat(getFilteredFeaturesWithParents(missingParentItems, allItems));
};

const getTreeOptions = (
  items: GetAllEnumsResponse[number]['keys'],
  id?: string
): CheckboxTreeOptionType[] =>
  items
    ?.filter((item) => item.parent === (id ?? null))
    .map((item) => ({
      label: item.labels[0]?.label ?? item.const_key,
      value: item.const_key,
      options: getTreeOptions(items, item.const_key),
    })) ?? [];
