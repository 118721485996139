import {Card, useDialog} from 'platform/components';
import {Box, Hide, HStack, Image, Link, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {isNotNil} from 'ramda';

import {i18n} from '@price-report/shared';

import {parseDate, suffixTestId, TestIdProps} from 'shared';

import {useIsPrintForced} from '../../hooks/useIsPrintForced';
import {UserComplaintDialog} from './components/UserComplaintDialog';

interface UserComplaintCardProps extends TestIdProps {
  created: string;
}

export function UserComplaintCard(props: UserComplaintCardProps) {
  const formatDate = useDateTimeFormatter();
  const {isPrint} = useIsPrintForced();
  const [isOpen, onOpen, onClose] = useDialog();
  const parsedDate = parseDate(props.created);
  const generationDate = isNotNil(parsedDate) ? formatDate('dateShort', parsedDate) : '';

  return (
    <Card variant={isPrint ? 'inlineWhite' : undefined}>
      <VStack spacing={4}>
        <HStack spacing={7} justify="space-between">
          <Text size="xxSmall" color="secondary">
            {i18n.t('page.overview.actions.pricingDescription', {generationDate})}
          </Text>
          <Box flexShrink={0}>
            <Image
              src="/assets/images/powered_by_carsdata.svg"
              alt="Powered by carsdata"
              width="4.6875rem"
              height="2.6875rem"
              data-testid={suffixTestId('carsdataLogo', props)}
            />
          </Box>
        </HStack>
        <Hide when={isPrint}>
          <Link
            title={i18n.t('page.overview.actions.reportError')}
            onClick={onOpen}
            leftIcon="content/mail"
            size="small"
            data-testid={suffixTestId('reportErrorLink', props)}
          />
        </Hide>
      </VStack>
      <UserComplaintDialog
        isOpen={isOpen}
        onClose={onClose}
        data-testid={suffixTestId('userComplaintDialog', props)}
      />
    </Card>
  );
}
