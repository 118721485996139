import {differenceInDays, startOfToday} from 'date-fns';
import {Flag} from 'platform/components';
import {
  AdaptiveImage,
  Box,
  CSSDimension,
  HStack,
  Image,
  Integer,
  RatioImageProps,
  Show,
  SizedImageProps,
} from 'platform/foundation';

import {CSSProperties} from 'react';

import {isNotNil} from 'ramda';

import {i18n} from '@price-report/shared';

import {Nullish} from 'shared';

import {makeStorageUrl} from '../../utils/makeStorageUrl';

interface VehicleImageProps {
  src?: string | null;
  isMyVehicle?: boolean;
  isSold: boolean;
  soldAt?: Date | Nullish;
  isSuspicious: boolean;
  maxWidth?: CSSDimension | Integer;
  maxHeight?: CSSDimension | Integer;
  ratio?: CSSProperties['aspectRatio'];
}

export function VehicleImage(props: VehicleImageProps) {
  const soldLabel = props.soldAt
    ? i18n.t('page.advertisements.soldNDaysAgo', {
        count: differenceInDays(startOfToday(), props.soldAt),
      })
    : i18n.t('page.advertisiments.sold');

  const adaptiveImageSizeProps: RatioImageProps | SizedImageProps = isNotNil(props.ratio)
    ? {
        ratio: props.ratio,
        maxWidth: props.maxWidth,
        maxHeight: props.maxHeight,
      }
    : {
        width: props.maxWidth ?? '100%',
        height: props.maxHeight ?? '100%',
      };

  return (
    <Box
      position="relative"
      backgroundColor={props.isMyVehicle ? 'palettes.green.20.100' : undefined}
      maxHeight={props.maxHeight}
      maxWidth={props.maxWidth}
      flexShrink={0}
    >
      <AdaptiveImage
        {...adaptiveImageSizeProps}
        fit="cover"
        url={props.src ?? undefined}
        makeUrl={makeStorageUrl}
        data-testid="vehicleImage"
        placeholder={
          <Image
            width="100%"
            height="100%"
            fit="cover"
            ratio={props.ratio}
            src={
              props.isMyVehicle
                ? '/assets/images/car-placeholder-selected.png'
                : '/assets/images/car-placeholder.jpeg'
            }
            data-testid="vehicleImagePlaceholder"
          />
        }
      />
      <Box position="absolute" left={2} top={2}>
        <HStack spacing={1}>
          <Show when={props.isSold}>
            <Flag size="small" colorScheme="green" label={soldLabel} data-testid="advertisements" />
          </Show>
          <Show when={props.isSuspicious}>
            <Flag
              size="small"
              colorScheme="neutral"
              label={i18n.t('page.advertisiments.suspicious')}
              data-testid="suspiciousFlag"
            />
          </Show>
          <Show when={props.isMyVehicle && isNotNil(props.src)}>
            <Flag
              size="small"
              colorScheme="blue"
              label={i18n.t('page.advertisements.yourVehicle')}
              data-testid="yourVehicleFlag"
            />
          </Show>
        </HStack>
      </Box>
    </Box>
  );
}
