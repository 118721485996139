import {parse, isValid} from 'date-fns';

import {Nullish} from 'shared';

import {Hour} from '../types/Hour';
import {Minute} from '../types/Minute';

/**
 * Splits TimeType value into hours and minutes. Returns null if given invalid or Nullish value.
 */
export const getHoursAndMinutesFromTime = (time: unknown | Nullish) => {
  if (!time) {
    return null;
  }

  // Try parsing with different formats
  const formats = ['HH:mm', 'H:m', 'h:mm a', 'h:m a', 'HH.mm', 'HHmm', 'hh:mm a', 'h:mm a'];
  let parsedDate = null;

  for (const format of formats) {
    const attempt = parse(time.toString(), format, new Date());
    if (isValid(attempt)) {
      parsedDate = attempt;
      break;
    }
  }

  if (!parsedDate) {
    return null;
  }

  const hour = parsedDate.getHours().toString().padStart(2, '0') as Hour;
  const minute = parsedDate.getMinutes().toString().padStart(2, '0') as Minute;

  return {hour, minute};
};
