import {motion} from 'framer-motion';
import {Box, CSSDimension, Integer, Show, Text, ValueByDevice} from 'platform/foundation';
import styled from 'styled-components';

import {ReactNode} from 'react';

import {TestIdProps} from 'shared';

import {OptionType} from '../../types/FormControlProps';

export type SegmentItemBaseProps<T> = {
  variant?: string;
  isDisabled?: boolean;
  minWidth?: CSSDimension | Integer | ValueByDevice<CSSDimension | Integer>;
} & OptionType<T, string | ReactNode>;

export interface SegmentItemProps<T> extends SegmentItemBaseProps<T>, TestIdProps {
  isSelected: boolean;
  onClick?: (value: T) => void;
  id: string;
}

export function SegmentItem<T extends string>(props: SegmentItemProps<T>) {
  function handleClick() {
    props.onClick?.(props.value);
  }

  return (
    <StyledSegmentItem
      onClick={!props.isDisabled ? handleClick : undefined}
      data-testid={props['data-testid']}
      key={props.value}
      $isDisabled={props.isDisabled}
    >
      <Show when={props.isSelected}>
        <StyledActiveBackground layoutId={props.id} />
      </Show>

      <StyledLabelWrapper>
        <Box
          paddingVertical={1}
          paddingHorizontal={2}
          width="100%"
          minWidth={props.minWidth ?? undefined}
        >
          <Text size="xSmall" align="center">
            {props.label}
          </Text>
        </Box>
      </StyledLabelWrapper>
    </StyledSegmentItem>
  );
}

const StyledLabelWrapper = styled.div`
  z-index: 2;
  width: 100%;
`;

const StyledActiveBackground = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: ${(props) => props.theme.colors.palettes.white['10']['100']};
  box-shadow: ${(props) => props.theme.shadows.elevation_2};
  border-radius: ${(props) => props.theme.radii.small};
  border-width: 1px;
  border-color: ${(props) => props.theme.colors.palettes.neutral['40']['100']};
  border-style: solid;
`;

const StyledSegmentItem = styled.div<{
  $isDisabled?: boolean;
}>`
  display: flex;
  position: relative;
  flex-grow: 1;
  padding: 1px;
  background-color: ${(props) => props.theme.colors.palettes.neutral['20']['100']};
  cursor: ${(props) => (props.$isDisabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.$isDisabled ? 0.6 : 1)};
  border-radius: ${(props) => props.theme.radii.small};
  text-align: center;
  &:hover {
    background: ${(props) => props.theme.colors.palettes.neutral['40']['100']};
  }
`;
