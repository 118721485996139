import {isNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {Hour} from '../types/Hour';
import {Minute} from '../types/Minute';

/**
 * Returns empty string if given Nullish value or 'Invalid time' if time is not valid. Otherwise returns given value.
 */
export const getInputValue = (hour: Hour | Nullish, minute: Minute | Nullish) => {
  if (isNilOrEmpty(hour) || isNilOrEmpty(minute)) {
    return '';
  }

  const time = `${hour}:${minute}`;

  const regex = new RegExp(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);

  if (!regex.test(time)) {
    return 'Invalid time';
  }

  return time;
};
