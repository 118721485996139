// eslint-disable-next-line eag/no-external-date-time-format-functions -- TODO [T20-67958]
import {addDays, format} from 'date-fns';
import {Align, Grid, GridItem, Space, Text} from 'platform/foundation';
import {convertStringLocaleToDateFns, useLocale} from 'platform/locale';

import {range} from 'ramda';

interface HeaderProps {
  startDate: Date;
}

export function Header(props: HeaderProps) {
  const {language} = useLocale();

  const days = range(0, 7).map((i) =>
    format(addDays(props.startDate, i), 'EEEEEE', {
      locale: convertStringLocaleToDateFns(language),
    })
  );

  return (
    <Grid columns={7} spacing={2}>
      {days.map((day) => (
        <GridItem key={day}>
          <Align center>
            <Text size="xSmall" color="secondary">
              {day}
            </Text>
          </Align>
          <Space vertical={2} />
        </GridItem>
      ))}
    </Grid>
  );
}
