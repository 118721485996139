import {IconButton, Overlay} from 'platform/components';
import {Box, Clickable, Heading, HStack, Show, VStack} from 'platform/foundation';
import {useTheme} from 'styled-components';

import {ReactNode, useEffect} from 'react';

import {isNotNil} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {ScrollWithHiddenScrollbarInCypress} from '../ScrollWithHiddenScrollbarInCypress/ScrollWithHiddenScrollbarInCypress';

interface ModalProps extends TestIdProps {
  children: ReactNode;
  heading: string;
  footer?: ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

export function Modal(props: ModalProps) {
  const theme = useTheme();
  const {isOpen, onClose} = props;

  // Disable scroll when menu is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Reset when component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  return (
    <Show when={isOpen}>
      <Box position="fixed" zIndex="MODAL" top={0} left={0} right={0} bottom={0}>
        <Clickable onClick={onClose}>
          <Overlay variant="neutral" density="default">
            <Box height="100%" padding={4}>
              <Clickable height="100%" onClick={(event) => event.stopPropagation()}>
                <Box
                  height="100%"
                  backgroundColor="general.white"
                  borderRadius={theme.components.Card.borderRadius}
                  boxShadow={theme.components.Card.elevation}
                >
                  <VStack height="100%">
                    <Box padding={4} data-testid={suffixTestId('modalHeader', props)}>
                      <HStack spacing={4} justify="space-between" align="center">
                        <Heading size={3}>{props.heading}</Heading>
                        <IconButton
                          icon="navigation/close"
                          onClick={onClose}
                          priority="secondary"
                          data-testid={suffixTestId('closeButton', props)}
                        />
                      </HStack>
                    </Box>
                    <ScrollWithHiddenScrollbarInCypress $flex={1}>
                      <Box padding={4}>{props.children}</Box>
                    </ScrollWithHiddenScrollbarInCypress>
                    <Show when={isNotNil(props.footer)}>
                      <Box padding={4} data-testid={suffixTestId('modalFooter', props)}>
                        {props.footer}
                      </Box>
                    </Show>
                  </VStack>
                </Box>
              </Clickable>
            </Box>
          </Overlay>
        </Clickable>
      </Box>
    </Show>
  );
}
