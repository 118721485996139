import {AnimatePresence, motion} from 'framer-motion';
import {
  Box,
  BoxProps,
  Clickable,
  getColorByPath,
  Heading,
  HStack,
  Icon,
  Show,
  Text,
  TextProps,
  ThemeColorPath,
  VStack,
} from 'platform/foundation';
import {useTheme} from 'styled-components';
import {match} from 'ts-pattern';

import {ReactNode} from 'react';

import {any, isNil, isNotNil, not} from 'ramda';
import {isTruthy} from 'ramda-adjunct';

import {DOT_CHARACTER, Nullish, suffixTestId, TestIdProps, useBoolean, useToggle} from 'shared';

import {ColorSchemeType} from '../../types/ColorSchemeType';
import {Action, Actions} from '../Actions/Actions';
import {FlagProps} from '../Flag/Flag';
import {Flags} from '../Flags/Flags';
import {IconButton} from '../IconButton/IconButton';
import {Parameters} from '../Parameters/Parameters';
import {Parameter} from '../Parameters/types/Parameter';
import {Separator} from '../Separator/Separator';
import {Tooltip} from '../Tooltip/Tooltip';
import {CardControl, CardControlProps} from './components/CardControl';

export type CardVariantType = 'default' | 'inlineGrey' | 'inlineWhite';

interface BaseCardProps extends TestIdProps {
  children?: ReactNode | ReactNode[];
  variant?: CardVariantType | Nullish;
  title?: string | Nullish;
  subtitle?: string | Nullish;
  parameters?: Parameter[];
  parametersProps?: TextProps;
  actions?: Action[];
  control?: CardControlProps;
  flags?: FlagProps[];
  tooltip?: string | Nullish;
  hasSeparator?: boolean | Nullish;
  colorStripe?: ColorSchemeType | Nullish;
}

interface ExpandableCardProps extends BaseCardProps {
  isFullHeight?: never;
  isExpandable?: boolean | Nullish;
  isExpanded?: boolean;
  isClosedByDefault?: boolean | Nullish;
  onExpandButtonClick?: VoidFunction;
}

interface FullHeightCardProps extends BaseCardProps {
  isFullHeight?: boolean;
  isExpandable?: never;
  isExpanded?: never;
  isClosedByDefault?: never;
  onExpandButtonClick?: never;
}

export type CardProps = ExpandableCardProps | FullHeightCardProps;

export function Card(props: CardProps) {
  const [isInternallyExpanded, toggleIsInternallyExpanded] = useToggle(!props.isClosedByDefault);
  const [isHeaderHovered, onHeaderMouseEnter, onHeaderMouseLeave] = useBoolean();

  const theme = useTheme();

  const isExpanded = props.isExpanded ?? isInternallyExpanded;
  const isInline = 'inlineWhite' === props.variant || 'inlineGrey' === props.variant;
  const hasColorStripe = !!props.colorStripe;

  const hasHeader = any(isTruthy, [
    props.title,
    props.subtitle,
    props.flags,
    props.parameters?.length,
    props.actions?.length,
    props.control,
    props.tooltip,
  ]);

  const isDefaultVariant = !props.variant || props.variant === 'default';
  const isVariantGray = props.variant === 'inlineGrey';

  const hasDefaultSizedAction = props.actions?.some(
    (item) => item.size === 'default' || !item.size
  );

  const getBodyTopPadding = () => {
    if (!isNil(props.hasSeparator)) {
      return props.hasSeparator ? 4 : 0;
    }

    if (!hasHeader) {
      return 4;
    }

    return isDefaultVariant ? 4 : 0;
  };

  const isSeparatorVisible = () => {
    if (!props.children) {
      return false;
    }

    if (!hasHeader) {
      return false;
    }

    if (!isExpanded) {
      return false;
    }

    if (!isNil(props.hasSeparator)) {
      return props.hasSeparator;
    }

    return isDefaultVariant;
  };

  const handleExpandCard = () => props.onExpandButtonClick?.() ?? toggleIsInternallyExpanded();

  const getColorStripe = () => {
    if (!props.colorStripe) {
      return;
    }

    const themeColorPath = match<ColorSchemeType, ThemeColorPath>(props.colorStripe)
      .with('black', () => 'palettes.neutral.900.100')
      .with('neutral', () => 'palettes.neutral.300.100')
      .with('blue', () => 'palettes.blue.70.100')
      .with('teal', () => 'palettes.teal.60.100')
      .with('green', () => 'palettes.green.60.100')
      .with('orange', () => 'palettes.orange.60.100')
      .with('yellow', () => 'palettes.yellow.60.100')
      .with('magenta', () => 'palettes.magenta.60.100')
      .with('purple', () => 'palettes.purple.60.100')
      .with('red', () => 'palettes.red.60.100')
      .exhaustive();

    return getColorByPath(themeColorPath, theme);
  };

  const cardBorder = match<[boolean, boolean], Partial<BoxProps>>([
    isDefaultVariant,
    hasColorStripe,
  ])
    .with([false, false], () => ({
      border: '1px solid',
      borderColor: 'general.separator',
    }))
    .with([false, true], () => ({
      borderLeft: `4px solid ${getColorStripe()}`,
      borderTop: `1px solid ${getColorByPath('general.separator', theme)}`,
      borderRight: `1px solid ${getColorByPath('general.separator', theme)}`,
      borderBottom: `1px solid ${getColorByPath('general.separator', theme)}`,
    }))
    .with([true, true], () => ({
      borderLeft: `4px solid ${getColorStripe()}`,
      borderTop: `${getColorByPath('general.black', theme)}`,
      borderRight: `${getColorByPath('general.black', theme)}`,
      borderBottom: `${getColorByPath('general.black', theme)}`,
    }))
    .with([true, false], () => ({}))
    .exhaustive();

  return (
    <Box
      borderRadius={theme.components.Card.borderRadius}
      boxShadow={not(isInline) ? theme.components.Card.elevation : undefined}
      backgroundColor={isVariantGray ? 'palettes.neutral.10.100' : 'palettes.white.10.100'}
      {...cardBorder}
      height={props.isFullHeight ? '100%' : undefined}
      data-testid={suffixTestId('cardWrapper', props)}
    >
      <VStack height={props.isFullHeight ? '100%' : undefined}>
        <Show when={hasHeader}>
          <ClickableHeader
            onHeaderClick={handleExpandCard}
            isExpandable={props.isExpandable}
            onHeaderMouseEnter={onHeaderMouseEnter}
            onHeaderMouseLeave={onHeaderMouseLeave}
          >
            <Box padding={4}>
              <HStack spacing={3}>
                <Show when={props.control}>
                  <VStack height={hasDefaultSizedAction ? 8 : 6} justify="center">
                    <StopPropagationWrapper>
                      <CardControl
                        {...props.control!}
                        data-testid={suffixTestId('cardControl', props)}
                      />
                    </StopPropagationWrapper>
                  </VStack>
                </Show>
                <VStack spacing={1} width="100%">
                  <HStack justify="space-between">
                    <HStack spacing={2} align="center">
                      <Show when={!!props.title}>
                        <Heading size={4} data-testid={suffixTestId('cardHeadingTitle', props)}>
                          {props.title}
                        </Heading>
                      </Show>
                      <Show when={isNotNil(props.tooltip)}>
                        <Tooltip
                          label={props.tooltip}
                          data-testid={suffixTestId('cardHeadingTooltip', props)}
                        >
                          <Icon value="action/help" size={4} color="text.secondary" />
                        </Tooltip>
                      </Show>
                      <Show when={!!props.subtitle}>
                        <Heading
                          size={4}
                          data-testid={suffixTestId('cardHeadingDotCharacter', props)}
                        >
                          {DOT_CHARACTER}
                        </Heading>
                        <Text
                          size="base"
                          data-testid={suffixTestId('cardHeadingSubtitleText', props)}
                        >
                          {props.subtitle}
                        </Text>
                      </Show>
                      <Show when={!!props.flags}>
                        <Flags
                          flags={props.flags}
                          spacing={2}
                          data-testid={suffixTestId('cardHeadingFlags', props)}
                        />
                      </Show>
                    </HStack>
                    <HStack spacing={2} align="center">
                      <StopPropagationWrapper>
                        <Actions
                          actions={props.actions}
                          size="default"
                          data-testid={suffixTestId('cardHeaderActions', props)}
                        />
                      </StopPropagationWrapper>
                      <Show when={props.isExpandable}>
                        <IconButton
                          size="small"
                          priority={isHeaderHovered ? 'secondary' : 'default'}
                          icon={isExpanded ? 'navigation/expand_less' : 'navigation/expand_more'}
                          data-testid={suffixTestId('cardHeaderExpandButton', props)}
                        />
                      </Show>
                    </HStack>
                  </HStack>
                  <Show when={props.parameters}>
                    <HStack>
                      <Parameters
                        data-testid={suffixTestId('cardHeadingParameters', props)}
                        parameters={props.parameters}
                        parametersProps={props.parametersProps}
                        size="small"
                        color="secondary"
                      />
                    </HStack>
                  </Show>
                </VStack>
              </HStack>
            </Box>
          </ClickableHeader>
        </Show>
        <Show when={isSeparatorVisible()}>
          <Separator spacing={0} data-testid={suffixTestId('cardSeparator', props)} />
        </Show>
        <Show when={isNotNil(props.children)}>
          <AnimatePresence initial={false}>
            {isExpanded ? (
              <motion.div
                key="cardBody"
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={{
                  visible: {
                    height: 'auto',
                    transition: {
                      duration: 0.3,
                      ease: [0.3, 0.1, 0.75, 0.9],
                    },
                    transformOrigin: 'top',
                  },
                  hidden: {
                    height: 0,
                    transition: {
                      duration: 0.25,
                      ease: [0.3, 0.1, 0.75, 0.9],
                    },
                    transformOrigin: 'top',
                  },
                }}
                style={{
                  flexGrow: 1,
                }}
              >
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={{
                    visible: {
                      opacity: 1,
                      transition: {
                        delay: 0.15,
                        duration: 0.15,
                      },
                      transformOrigin: 'top',
                    },
                    hidden: {
                      opacity: 0,
                      transition: {
                        delay: 0,
                        duration: 0.1,
                      },
                      transformOrigin: 'top',
                    },
                  }}
                  style={{
                    height: '100%',
                  }}
                >
                  <Box
                    flex={1}
                    paddingBottom={4}
                    paddingHorizontal={4}
                    paddingTop={getBodyTopPadding()}
                    height={props.isFullHeight ? '100%' : undefined}
                    data-testid={suffixTestId('cardBody', props)}
                  >
                    {props.children}
                  </Box>
                </motion.div>
              </motion.div>
            ) : null}
          </AnimatePresence>
        </Show>
      </VStack>
    </Box>
  );
}

const StopPropagationWrapper = (props: {children: ReactNode}) => (
  <div onClick={(e) => e.stopPropagation()}>{props.children}</div>
);

interface ClickableHeaderProps {
  isExpandable: boolean | Nullish;
  onHeaderClick: () => void;
  onHeaderMouseEnter: () => void;
  onHeaderMouseLeave: () => void;
  children: ReactNode;
}

const ClickableHeader = (props: ClickableHeaderProps) => {
  if (!props.isExpandable) {
    return props.children;
  }

  return (
    <Clickable
      onClick={props.onHeaderClick}
      onMouseEnter={props.onHeaderMouseEnter}
      onMouseLeave={props.onHeaderMouseLeave}
    >
      {props.children}
    </Clickable>
  );
};
