import {isNil} from 'ramda';

import {AvailableCar, PriceReportType} from '@price-report/shared';

export const formatMyVehicle = (priceReport: PriceReportType): AvailableCar => {
  const vehicle = priceReport.vehicle;

  return {
    ad_id: 'my-vehicle',
    ad_title: 'My Vehicle',
    car_style: vehicle.bodyStyle,
    drive_type: vehicle.drive,
    feature_score: priceReport.featuresLevel,
    features: vehicle.features,
    fuel_type: vehicle.fuelType,
    highlight_features: vehicle.featuresHighlighted,
    make: vehicle.make,
    mileage: vehicle.mileage,
    model: vehicle.modelFamily,
    power: vehicle.power,
    price_with_vat: priceReport.sellingPrice.recommended.value,
    original_price_with_vat: priceReport.sellingPrice.recommended.value,
    original_currency: priceReport.sellingPrice.recommended.currency,
    first_scraped_price_with_vat: null,
    first_scraped_price_currency: null,
    thumbnail_url: null,
    transmission: vehicle.transmission,
    year: vehicle.firstRegistrationOnYear,
    url: null,
    is_sold: false,
    suspicious: false,
    last_occurrence: null,
    days_on_stock: isNil(priceReport.turnover) ? 0 : Math.round(priceReport.turnover),
    vat_reclaimable: vehicle.isVatDeductible ?? false,
  };
};
