import {
  Box,
  getValueByDevice,
  Image,
  Space,
  Stack,
  Text,
  useDevice,
  VStack,
} from 'platform/foundation';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {i18n, PriceReportType} from '@price-report/shared';

import {parseDate, suffixTestId, TestIdProps} from 'shared';

import {VehicleImage} from '../../../components/VehicleImage/VehicleImage';
import {CatalogueType} from '../../../hooks/useCatalogue';
import {AvailableCarWithLogo} from '../types/available-car-with-logo';
import {RowHeader} from './RowHeader';

interface VehicleCardProps extends TestIdProps {
  priceReport: PriceReportType;
  catalogue: CatalogueType;
  vehicle: AvailableCarWithLogo;
  isMyVehicle: boolean;
}

export function VehicleCard(props: VehicleCardProps) {
  const device = useDevice();

  const soldAtDate = isNotNil(props.vehicle.last_occurrence)
    ? parseDate(props.vehicle.last_occurrence)
    : undefined;

  return (
    <Box
      backgroundColor={props.isMyVehicle ? 'palettes.teal.20.100' : 'palettes.neutral.10.100'}
      borderRadius="small"
      border="1px solid"
      borderColor="palettes.neutral.40.100"
    >
      <Stack direction={['column', 'row', 'row', 'row']}>
        {isNil(props.priceReport.vehicle.imageUrl) && isNotNil(props.vehicle.logoUrl) ? (
          <Box width={['100%', 68, 68, 68]} height={[35, 40, 40, 40]} flexShrink={0}>
            <VStack height="100%" justify="center" align="center">
              <Image
                src={props.vehicle.logoUrl}
                width="auto"
                data-testid={suffixTestId('vehicleLogo', props)}
              />
              <Space vertical={3} />
              <Text size="xSmall" color="tertiary" alternative>
                {i18n.t('page.advertisements.yourVehicle')}
              </Text>
            </VStack>
          </Box>
        ) : (
          <VehicleImage
            src={
              props.isMyVehicle
                ? props.priceReport.vehicle.imageUrl
                : props.vehicle.thumbnail_url?.full
            }
            isMyVehicle={props.isMyVehicle}
            isSold={!!props.vehicle.is_sold}
            soldAt={soldAtDate}
            isSuspicious={!!props.vehicle.suspicious}
            maxWidth={getValueByDevice(device, '100%', 68, 68, 68)}
            maxHeight={getValueByDevice(device, undefined, 45, 45, 45)}
            ratio={getValueByDevice(device, '3 / 2', undefined, undefined, undefined)}
          />
        )}
        <Box padding={4} flexGrow={1}>
          <RowHeader
            priceReport={props.priceReport}
            catalogue={props.catalogue}
            vehicle={props.vehicle}
            isMyVehicle={props.isMyVehicle}
            data-testid={suffixTestId('rowHeader', props)}
          />
        </Box>
      </Stack>
    </Box>
  );
}
